import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, ReactNode } from "react";

interface PatchNote {
  title: string;
  previews: ReactNode[];
  content: ReactNode;
}

interface Tag {
  label: string;
  color: string;
}

class TagType {
  static readonly NEW: Tag = { label: "Nouveauté", color: "var(--green)" };
  static readonly UPDATE: Tag = { label: "Amélioration", color: "var(--primary-color)" };
  static readonly CORRECTED: Tag = { label: "Correction", color: "var(--red-lighter)" };
}

const newsStyle: CSSProperties = {
  padding: "0.2em 0.6em 0.3em",
  borderRadius: "12px",
  fontWeight: "500",
  color: "var(--background)",
};

const newsLightStyle: CSSProperties = {
  height: "25px",
  width: "25px",
  borderRadius: "50%",
  display: "inline-block",
};

const ulStyle: CSSProperties = {
  listStyle: "none",
  padding: 0,
  margin: "1rem",
};

const liStyle: CSSProperties = {
  marginBottom: "1rem",
};

const titleStyle: CSSProperties = {
  fontWeight: 600,
};

const Divider = () => {
  return <div style={{ marginBottom: "0.5rem" }} />;
};

const Nametag = ({ title, type, lightFormat }: { title: string; type: Tag; lightFormat?: boolean }) => {
  const tagStyle: CSSProperties = lightFormat ? newsLightStyle : newsStyle;
  return (
    <span style={lightFormat ? { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } : {}}>
      <span style={{ backgroundColor: type.color, ...tagStyle }}>{lightFormat ? " " : type.label}</span>{" "}
      <span style={lightFormat ? {} : titleStyle}>{title}</span>
    </span>
  );
};

export const patchNotes: PatchNote[] = [
  {
    title: "Mise à jour du 20/04/2023",
    previews: [<Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Un bug concernant les photos venant d'appareils mobiles comme un téléphone ou certains appareils photos causait une rotation non gérée.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 19/04/2023",
    previews: [<Nametag title="Dropzone" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Dropzone" type={TagType.NEW} />
          <Divider />
          <span>
            Vous avez maintenant la possibilité d'ajouter vos médias les déposant dans la zone prévue à cette effet, dans la fenêtre d'ajout de média.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 17/04/2023",
    previews: [<Nametag title="Rotation des images" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Rotation des images" type={TagType.NEW} />
          <Divider />
          <span>Il est maintenant possible d'appliquer une rotation à vos photos.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 25/01/2023",
    previews: [<Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Les tutoriels intéractifs sont masqués pour les utilisateurs n'étant pas <span style={{ fontWeight: 600 }}>Administrateur</span>.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 04/01/2023",
    previews: [
      <Nametag title="Purge des dossiers" type={TagType.NEW} lightFormat />,
      <Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Purge des dossiers" type={TagType.NEW} />
          <Divider />
          <span>
            Un bouton <span style={{ fontWeight: 600, color: "var(--red)" }}>Purger</span> est visible dans la configuration d'un dossier (Uniquement
            pour les administrateurs de l'entreprise). Cela permet de supprimer toutes les photos d'un dossier rapidement.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>
            La connexion d'un dossier avait des soucis d'actualisation ce qui pouvait causer le déclenchement de requêtes non pertinentes et causer
            des erreurs.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 12/10/2022",
    previews: [<Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>Télécharger un dossier vide renvoyait une erreur, cela télécharge maintenant une archive vide.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 11/10/2022",
    previews: [
      <Nametag title="Permissions administrateur" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Permissions administrateur" type={TagType.UPDATE} />
          <Divider />
          <span>Les permissions des administrateurs de dossier sont maintenant plus permissives.</span>
          <Divider />
          <span>La création de sous-dossier, la supression de ceux-ci, la gestion de rôles et de notifications leurs est maintenant possible.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>Un bug d'actualisation de l'affichage lors de l'ajout d'un nouvel utilisateur à un dossier a été corrigé.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 05/10/2022",
    previews: [<Nametag title="Synchronisation des accès" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Synchronisation des accès" type={TagType.NEW} />
          <Divider />
          <span>Vous pouvez maintenant synchroniser les accès d'un sous-dossier avec ceux de son parent.</span>
          <Divider />
          <span>
            Pour se faire, vous devez appuyer sur le bouton <span style={{ color: "var(--primary-color)" }}>Synchroniser les accès avec</span>
          </span>
          <Divider />
          <span>Attention, cela efface les anciens droits et ne copie que les accès sur le moment.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 03/10/2022",
    previews: [
      <Nametag title="Notifications" type={TagType.NEW} lightFormat />,
      <Nametag title="Gestion des accès" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Changement visuels" type={TagType.UPDATE} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Notifications" type={TagType.NEW} />
          <Divider />
          <span>Il est désormais possible de recevoir des notifications lors de l'ajout d'une image dans un dossier.</span>
          <Divider />
          <span>
            Pour se faire, un administrateur de l'entreprise doit aller dans les paramètres du dossier voulu et dans l'onglet{" "}
            <span style={{ color: "black", fontWeight: 600 }}>Accès et notifications</span> puis cliquer sur l'icône <FontAwesomeIcon icon={faBell} />{" "}
            de l'utilisateur concerné.
          </span>
          <Divider />
          <span>
            Les notifications se présentent sous la forme d'un mail envoyé à l'adresse de l'utilisateur, si aucune n'est définie, il ne pourra
            recevoir les notifications.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Gestion des accès" type={TagType.UPDATE} />
          <Divider />
          <span>
            La gestion des accès a été revue de manière à être facultative. De plus, le menu a été réuni avec celui de modification d'un dossier.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Changements visuels" type={TagType.UPDATE} />
          <Divider />
          <span>Certains éléments de l'interface ont été visuellement améliorés.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 07/09/2022",
    previews: [
      <Nametag title="Hiérarchie visible" type={TagType.NEW} lightFormat />,
      <Nametag title="Intelligence Collective" type={TagType.NEW} lightFormat />,
      <Nametag title="Utilisateurs supprimés" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Surbrillance du dossier courant" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Correction de bugs" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Hiérarchie visible" type={TagType.NEW} />
          <Divider />
          <span>La hiérarchie des dossiers a été ajoutée dans les différentes popup concernés.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Intelligence Collective" type={TagType.NEW} />
          <Divider />
          <span>La connexion Photos & Vidéos X Intelligence Collective a été mise en avant dans l'accueil.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Utilisateurs supprimés" type={TagType.UPDATE} />
          <Divider />
          <span>Les utilisateurs supprimés et ceux qui n'ont pas l'accès à une application ne sont plus affichés dans la gestion des accès.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Surbrillance du dossier courant" type={TagType.UPDATE} />
          <Divider />
          <span>Le dossier courant est maintenant affiché en bleu dans la liste des dossiers.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Correction de bugs" type={TagType.CORRECTED} />
          <Divider />
          <span>Certains anciens médias corrompus ne pouvaient être supprimés.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 31/08/2022",
    previews: [
      <Nametag title="Harmonisation de l'apparence" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Déselectionner un dossier" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Agrandissement des prévisualisations" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Correction de bugs" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Harmonisation de l'apparence" type={TagType.UPDATE} />
          <Divider />
          <span>L'apparence de l'application a été harmonisée avec celle des autres applications.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Déselectionner un dossier" type={TagType.UPDATE} />
          <Divider />
          <span>Le texte "Accès aux médias" a été remplacé par un bouton "Tous les dossiers" permettant de revenir à la racine des dossiers.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Agrandissement des prévisualisations" type={TagType.UPDATE} />
          <Divider />
          <span>Les fenêtres de création/modifications et de visualisation d'un média utilisent maintenant plus de place sur l'écran.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Correction de bugs" type={TagType.CORRECTED} />
          <Divider />
          <span>Plusieurs bugs ont été corrigés :</span>
          <ul>
            <li>La recherche ne fonctionnait pas correctement lorsque l'on était dans un dossier,</li>
            <li>Le nombre total de médias représentait le nombre de médias chargés et non le nombre total,</li>
            <li>Donner des droits dans un sous-dossier à un utilisateur qui n'avait pas accès au dossier parent ne fonctionnait pas.</li>
          </ul>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 24/08/2022",
    previews: [<Nametag title="Allongement des descriptions" type={TagType.UPDATE} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Allongement des descriptions" type={TagType.NEW} />
          <Divider />
          <span>La limite de caractères pour la description d'un média est passée de 250 à 700.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 19/08/2022",
    previews: [<Nametag title="Gestion des accès aux dossiers" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Gestion des accès aux dossiers" type={TagType.NEW} />
          <Divider />
          <span>Vous pouvez désormais gérer l'accès a vos dossier, en choisissant qui y a accès avec différent niveau de permissions.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 18/07/2022",
    previews: [
      <Nametag title="Liste des mises à jours" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Corrections de bugs" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Liste des mises à jours" type={TagType.UPDATE} />
          <Divider />
          <span>La liste des mises à jour à eu le droit à une nouvelle apparence ! Elle est maintenant plus détaillée et plus lisible.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Corrections de bugs" type={TagType.CORRECTED} />
          <Divider />
          <span>Plusieurs bugs ont été corrigés :</span>
          <ul>
            <li>Les données à l'écran quand un sous-dossier est modifié ne se mettaient pas à jour,</li>
            <li>Le bouton pour créer une catégorie s'affichait pour les utilisateurs non autorisés, même si il ne fonctionnait pas,</li>
            <li>Les sous-dossiers ne s'affichaient pas pour les utilisateurs normaux.</li>
          </ul>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 04/07/2022",
    previews: [<Nametag title="Sélection multiple" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Sélection multiple" type={TagType.NEW} />
          <Divider />
          <span>
            Vous pouvez désormais sélectionner plusieurs médias et effectuer des actions comme la suppression ou le téléchargement d'un seul coup.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 06/06/2022",
    previews: [
      <Nametag title="Modifications du système de tri" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Amélioration de l'ergonomie" type={TagType.UPDATE} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Partage d'images" type={TagType.NEW} />
          <Divider />
          <span>Vous pouvez générer un lien pour partager une image de manière permanente.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Scan par référence" type={TagType.NEW} />
          <Divider />
          <span>Vous pouvez dorénavant rechercher une image sur l'application mobile en scannant la référence associée à l'image.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Modifications du système de tri" type={TagType.UPDATE} />
          <Divider />
          <span>Par défaut, les médias sont désormais affichés du plus récent au plus ancien.</span>
          <Divider />
          <span>Vous pouvez maintenant trier les médias par date de création ou par ordre alphabétique.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Amélioration de l'ergonomie" type={TagType.UPDATE} />
          <Divider />
          <span>Effectuer un clique droit sur un dossier dans la liste des dossiers à gauche de l'écran ouvre désormais le menu.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 25/05/2022",
    previews: [
      <Nametag title="Page d'accueil 🏠" type={TagType.NEW} lightFormat />,
      <Nametag title="Modifications du système de tri" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Changement d'appellation" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Page d'accueil 🏠" type={TagType.NEW} />
          <Divider />
          <span>
            Nos applications disposent désormais d'une page d'accueil. Depuis cette page, vous aurez accès à la liste des mises à jour et à différents
            autres modules en fonction de l'application.
          </span>
          <Divider />
          <span>
            D'autres modules sont en cours de conception, n'hésitez pas à suivre les mises à jour afin d'être au courant de leur avancement.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Modifications du système de tri" type={TagType.UPDATE} />
          <Divider />
          <span>Vous pouvez désormais trier les photos et vidéos par date d'ajout et par nom.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Changement d'appellation" type={TagType.CORRECTED} />
          <Divider />
          <span>Le bouton "partager" a été renommée "connecter" sur les dossiers. Il permet de connecter un dossier à une autre application.</span>
        </li>
      </ul>
    ),
  },
];
